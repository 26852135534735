@import './settings.scss';
@import "./typography.scss";

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: $color-background;
  font-family: $font-text;
  font-size: ($base-size * 1.1);
  color: $color-text;
  letter-spacing: 0.5px;
}

.container {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}

.grecaptcha-badge{
  visibility:hidden
}

@import "./typography.scss";
