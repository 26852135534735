@import '../../../styles/settings.scss';

.wrapper{
    margin-top: 40px;
    .title{
        font-size: 3*$base-size;
        margin-bottom: 30px;

        @media (max-width: 770px) {
            font-size: 2*$base-size;
            margin-top: 30px;
            text-align: center;
        }
    }

    .gallery{
        display: flex;
        justify-content: space-evenly;
        gap: 20px;

        @media (max-width: 770px) {
            flex-direction: column;
            justify-content: center;
        }

        .box{
            position: relative;
            text-align: center;

            @media (max-width: 770px) {
                height: auto;
                width: 300px;
                margin: auto;
            }

            img{
                width: 100%;
                height: 100%;
                filter: brightness(0.4);
            }

            .subtitle{
                position: absolute;
                margin: 0;
                color: $color-light;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: $transition;
                font-size: 24px;
            }
            @media (min-width: 780px) {
                &:hover{
                    .subtitle{
                        transform: translate(-50%, -50%) scale(1.2);
                    }
                }
            } 
        }
    }

    .desriptionWrapper{
        display: flex;
        gap: 150px;
        justify-content: space-evenly;
        margin-top: 70px;
        align-items: center;

        @media (max-width: 1200px) {
            gap:70px
        }
        .description{
            flex: 60%;
            .desTitle{
                font-size: 3*$base-size;
            }
            .desBody{
                font-size: 1.5*$base-size;
                font-family: $font-text-body;
                line-height: 33px;
                @media (max-width: 1200px) {
                    font-size: 1.2*$base-size;
                    line-height: 25px;
                }
            }

            .btnWrapper{
                display: flex;
                justify-content: center;
                button{
                    color: $color-light;
                    display: block;
                    text-align: center;   
                    text-decoration: none;
                    background-color: $color-text;
                    border: 1px solid $color-text;
                    &:hover{
                        background-color: $color-background;
                        color: $color-text;
                    }
                }
            }

        }
        .imgWrapper{
            flex: 40%;
            @media ( max-width: 770px){
                display: none;
            }
            img{
                width: 100%;
                height: auto;
            }
        }

    }
}