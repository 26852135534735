@import '../../../styles/settings.scss';

.wrapper{
    display: flex;
    justify-content: space-between;
    width: 90.3%;
    max-width: 1405px;
    position: fixed;  
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $color-background;    
    z-index: 10;
 

    .imgWrapper{
        height: 98px;
        width: auto;
        margin-bottom: 10px;

        @media (max-width: 1000px) {
            height: 50px;
        }
        
        img{
           width: auto;
           height: 100%;
           object-fit: contain;
           cursor: pointer;
        }
    }

    .menuWrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        margin: auto 0;


        i{
            font-size: 2* $base-size;
        }

        .btnHandler{
            display: none;
        }

        .menu{
            display: flex;
            
            ul{
                list-style-type: none;
                margin: 0;
                display: flex;
            }
            li {
                padding: $base-size;

            }
            a{
                text-decoration: none;
                color: $color-text;
                font-size: 2*$base-size;
                transition: $transition;
                &:hover{
                    color: $color-grey;
                }
            }
        }


        @media (max-width: 1000px){
            .btnHandler{
                z-index: 13;
                display: block;
            }
    
            .menu{
                display: flex;
                position: absolute;
                top: -30px;
                right: 0;
                z-index: 12;
                height: 120vh;
    
                max-width: 0; 
                overflow: hidden; 
                transition: max-width 0.5s ease-in-out; 
                background: linear-gradient(to right, $color-dark , $color-background 60%);
    
                &.show{
                    display: flex;
                    z-index: 12;
                    max-width: 1000px;
                    
                }
    
                ul{
                    flex-direction: column;
                    list-style-type: none;
                    margin: 0;
                    margin-top: 80px;
                    padding: 0;
                    text-align: center;
                    background: linear-gradient(to right, $color-dark , $color-background 60%);
                    border-radius: $radius;
                    width: 200px;
                    li {
                        padding: $base-size;
                    }
                    a{
                        text-decoration: none;
                        color: $color-text;
                        font-size: 2*$base-size;

                    }
                }
            }
        }



    }
}