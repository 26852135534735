@import '../../../styles/settings.scss';

.wrapper{
    margin-top: 40px;
    .title{
        font-size: 3*$base-size;
        @media (max-width: 770px) {
            font-size: 2*$base-size;
            text-align: center;
        }
        
    }
    .description{
        font-size: 1.5*$base-size;
        font-family: $font-text-body;
        line-height: 33px;
        width: 60%;
        @media (max-width: 1200px) {
            font-size: 1.2*$base-size;
            line-height: 25px;
            text-align: center;
            width: 100%;
        }
    }
}