$color-text: #274035;
$color-background: #F3F2F1;
$color-dark: #D8D8D8;
$color-light: #FFFFFF;
$color-grey: #999790;
$color-red: red;
$color-blue: blue;
$color-green: green;

$font-text: "Playfair Display", serif;
$font-text-body: "Inter", sans-serif;

$radius: 5px;
$base-size: 12px;
$half-size: 6px;
$transition: all 0.5s;
