@import '../../../styles/settings.scss';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 999; 
    &.show{
        display:flex;
    }
}

.wrapper{
    position: fixed;
    max-width: 90%;
    max-height: 90%;
    width: 90vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-background;
    display: flex;
    align-items: center;
    justify-content: center; 
    z-index: 1000;
    flex-direction: column;

    @media (max-width: 780px){
        width: 100%;
        height: 100%;
    }
}

.specCard{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;

    gap: 20px;
    @media (max-width: 780px){
        flex-direction: column;
    }

    .imagesSection{
        margin-top: 1vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 50%;
        max-width: 100%;
        max-height: 100%;

        .mainImg{
            position: relative;
            display: flex;
            width: auto;
            height: auto;
            padding: 2vh;
            margin: 0; 

            img{
                width: 100%;
                height: auto;
                max-height: 65vh;
                object-fit: contain;
                @media (max-width: 780px){
                    max-height: 35vh;
                }
            }

            .prevButton, .nextButton {
                background-color: $color-background;
                border: none;
                font-size: 2em;
                cursor: pointer;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                visibility: hidden;
                opacity: 0;
                transition: all 0.3s ease;
            }
            
            .prevButton {
                left: 10px; 
            }
            
            .nextButton {
                right: 10px; 
            }
            &:hover{
                .prevButton, .nextButton {
                    visibility: visible;
                    opacity: 1;
                }
            }

        }
        .slider{
            padding-bottom: 2vh;
            @media (max-width: 780px){
                display: none;
            }
        }
    }

    .descriptionSection{
        margin-top: 3vh;
        flex: 50%;
        overflow-y: auto;
        max-height: 80vh;
        
        @media (max-width: 780px){
            margin-top: 10px;
            width: 90%;
            margin: auto;
            
            .title, .category{
                text-align: center;
            }
        }

        .title{
            font-size: 2.5*$base-size;
            margin-bottom: 10px;
        }
        .category{
            font-family: $font-text-body;
            font-style: italic;
        }
        .description{
            font-size: $base-size;
            margin-right: 20px;
            font-family: $font-text-body;
            line-height: 20px;
            font-size:  1.2*$base-size;
        }
    }


    .close{
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(50%, -50%); 
        background-color: transparent;
        color: $color-text;
        border-radius: 20px;
        font-size: 3*$base-size;
        padding: 0;
        &:hover{
            color: $color-red;
        }
    }
}