@import '../../../styles/settings.scss';

.footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 20px;

    .copyright{
        color: $color-grey;
        a{
            text-decoration: none;
            color: $color-text;
            margin-left: 5px;;
        }
    }

    .social{
        ul {
            display: flex;
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        li{
            margin: 0 5px;
        }
        a {
            text-decoration: none;
            color: $color-text;
            padding: 5px;
            &:hover{
                font-size: 24px;
                &.fb{
                    color: $color-blue;
                }
                &.insta{
                    color:$color-red;
                }
                & {
                    transition: $transition;
                }
            }
        }
    }
}