

.wrapper{
    position: relative;
    margin-top:70px;

    .imgWrapper{
        width: 60%;
        height: auto;
        img{
            width: 100%;
            height: auto;
        }

        @media (max-width: 770px){
            display: none;
        }
    }

    .titleWrapper{
        position: absolute;
        right: 0;
        top: 30%;
        text-align: end;
        @media (max-width: 770px){
            margin-top: 40px;
            position: relative;
        }

        .title{
            font-size: 100px;

            @media (max-width: 1200px){
                font-size: 70px;
            }

            @media (max-width: 770px){
                font-size: 50px;
            }
        }
        .subtitle{
            font-size: 24px;
            margin: 0;

            @media (max-width: 1200px){
                font-size: 18px;
            }
            @media (max-width: 770px){
                font-size: 16px;
            }

        }

    }
}