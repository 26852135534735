@import '../../../styles/settings.scss';

.button {
    background: $color-background;
    border: 0;
    color: $color-text;
    text-align: center;
    line-height: 1.3;
    padding: $base-size;
    margin: $half-size;
    border-radius: $radius;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    transition: $transition;
    
    &:hover {
        background: darken($color-background, 10%);
    }
}