@import '../../../styles/settings.scss';

.wrapper{
    width: 80%;
    margin: 30px auto;
    text-align: center;

    .title{
        font-family: $font-text-body;
    }

    label{
        display: block;
        margin: 16px 0;
    }

    input{
        border: none;
        background-color: inherit;
        width: 100%;
        border-bottom: 1px solid $color-grey;
        margin-bottom: 5px;
        font-family: $font-text-body;
        padding-bottom: 10px;
    }
    
    .personalData{
        display: flex;
        gap: 20px;
        text-align: start;
        @media (max-width: 780px){
            flex-direction: column;
        }

        .dataWrapper{
           flex: 1;
        }
    }

    .msg{
        text-align: start;
        textarea{
            border: none;
            background-color: inherit;
            width: 100%;
            height: auto;
            border-bottom: 1px solid $color-grey;
            margin-bottom: 5px;
            font-family: $font-text-body;
            padding-bottom: 10px;
        }
    }



    .btnWrapper{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        .btn{
            color: $color-light;
            padding: 10px 40px;
            display: block;
            text-align: center;   
            text-decoration: none;
            background-color: $color-text;
            border: 1px solid $color-text;
            &:hover{
                background-color: $color-background;
                color: $color-text;
            }
        }
    }

    .success{
        display: flex;
        justify-content: center;
        p{
            color: $color-green;
            background-color: darken($color-background, 10%);
            padding: 10px;
            border-radius: $radius;
        }
    }
    .fail{
        display: flex;
        justify-content: center;
        p{
            color: $color-red;
            background-color: darken($color-background, 10%);
            padding: 10px;
            border-radius: $radius;
        }
    }

    .honeypot{
        display: none;
    }
}